import { useLinkSetupStore } from '@/stores/useLinkSetupStore';
import { faCheck, faPencil, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, Button, Flex, Image, Text, TextInput, Tooltip } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import QRCode from '../../../../assets/images/common/qr-code.svg';
import { useQRModalStore } from '@/stores/useQRModalStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { usePlanStore } from '@/stores/usePlanStore';
import { notifications } from '@mantine/notifications';

export const ShortenedURL = ({ link }: { link: any }) => {
  const navigate = useNavigate();
  const clipboard = useClipboard({ timeout: 1000 });
  const [editShortURL, setEditShortURL] = useState('');
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [plan] = usePlanStore((state) => [state.plan]);

  const [setQRModalOpened, setQRModalURL, setQRModalProps, setFgColor, setBgColor] =
    useQRModalStore((state) => [
      state.setIsOpen,
      state.setURL,
      state.setProps,
      state.setFgColor,
      state.setBgColor,
    ]);

  const [setLink, editShortenURL, isSlugEditable, setIsSlugEditable] = useLinkSetupStore((state) => [
    state.setLink,
    state.editShortenURL,
    state.isSlugEditable,
    state.setIsSlugEditable,
  ]);

  const handleEditShortenURL = async () => {
    const res = await editShortenURL(defaultWorkspace?._id || '', link._id, editShortURL);
    if (res) {
      setIsSlugEditable(false);
    }
  };

  useEffect(() => {
    return () => {
      setIsSlugEditable(false);
      setEditShortURL('');
    };
  }, []);

  return (
    <>
      <Flex align={'center'} mb={8}>
        <TextInput
          mr={'sm'}
          styles={{
            wrapper: {
              backgroundColor: 'white',
            },
            input: {
              backgroundColor: 'white',
            },
          }}
          style={{
            flexGrow: 1,
          }}
          label={'Shortened link'}
          //   radius="xl"
          value={isSlugEditable ? editShortURL : link.short_url}
          onChange={(event) => {
            setEditShortURL(event.currentTarget.value);
          }}
          disabled={!isSlugEditable}
          placeholder="Your shortened URL"
          leftSection={
            <Text ml={12} fz={15}>
              {link?.domain_attributes?.url}
            </Text>
          }
          leftSectionWidth={
            link?.domain_attributes?.url ? link?.domain_attributes?.url.length * 8.1 : 12
          }
          rightSectionWidth={isSlugEditable ? 148 : 120}
          rightSection={
            <Flex align={'center'}>
              {isSlugEditable ? (
                <>
                  <ActionIcon variant="subtle" onClick={() => handleEditShortenURL()}>
                    <FontAwesomeIcon color="green" icon={faCheck} />
                  </ActionIcon>
                  <ActionIcon
                    variant="subtle"
                    mr={4}
                    onClick={() => {
                      setEditShortURL('');
                      setIsSlugEditable(false);
                    }}
                  >
                    <FontAwesomeIcon color="red" icon={faTimes} />
                  </ActionIcon>
                </>
              ) : (
                <ActionIcon
                  variant="subtle"
                  mr={4}
                  onClick={() => {
                    if (plan?.type_of === "trial") {
                      notifications.show({
                        title: 'Trial Plan',
                        message: 'Please upgrade your plan to edit shortened URL.',
                        color: 'red',
                      })
                      return;
                    }
                    setEditShortURL(link.short_url);
                    setIsSlugEditable(true);
                  }}
                >
                  <FontAwesomeIcon color="gray" icon={faPencil} />
                </ActionIcon>
              )}

              <Button
                color={clipboard.copied ? 'teal' : 'black'}
                mr={-6}
                onClick={() => clipboard.copy(`${link.domain_attributes.url}${link.short_url}`)}
              >
                {clipboard.copied ? 'Copied' : 'Copy Link'}
              </Button>
            </Flex>
          }
        />
        <Tooltip label="Download QR Code">
          <ActionIcon
            color="gray"
            ml={12}
            mt={24}
            size="lg"
            variant="outline"
            onClick={() => {
              if (link.qr_code) {
                // QR code exists, navigate to edit page
                navigate(`/qr-codes/${link.workspace_id}/setup/${link.qr_code._id}`);
              } else {
                // No QR code exists, set link in store and navigate to create page
                setLink({
                  _id: link._id,
                  url: link.url,
                  short_url: link.short_url,
                  campaign_id: link.campaign_id,
                  workspace_id: link.workspace_id,
                  domain_attributes: link.domain_attributes,
                  tags: link.tags || [],
                  meta: link.meta || {},
                  title: link.title || '',
                  description: link.description || ''
                });
                navigate(`/qr-codes/${link.workspace_id}/setup?source_type=link&link_id=${link._id}`);
              }
            }}
          >
            <Image src={QRCode} h={20} w={20} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    </>
  );
};
