import {
  faArrowRightFromBracket,
  faArrowsRotate,
  faBook,
  faBookmark,
  faBullhorn,
  faCirclePlus,
  faCode,
  faCreditCard,
  faEllipsisVertical,
  faFileChartPie,
  faGear,
  faGlobe,
  faHome,
  faLink,
  faLinkHorizontal,
  faMap,
  faNetworkWired,
  faPieChart,
  faQuestionCircle,
  faRss,
  faUser,
  faUsers,
  faDollar,
  faQrcode
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Avatar,
  Box,
  Divider,
  Flex,
  Group,
  Image,
  Menu,
  Text,
  Tooltip,
} from '@mantine/core';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { WorkspaceMenu } from '../Menus/WorkspaceMenu/WorkspaceMenu';
import classes from './Navbar.module.css';
import { useUserStore } from '@/stores/useUserStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission, isAccountActive, isWhitelabelDomain } from '@/utils/CommonUtility';
import ReplugLogo from '@/assets/images/logos/replug-logo.svg';
import { APP_URL } from '@/constants/Constants';
import { DEFUALT_BIO_LINK_VALUES } from '@replug/utils';
import { useBioLinkSetupStore } from '@/stores/Bio/useBioLinkSetupStore';
import FrillWidget from '../Frill/FrillWidget';
import FrillNotificationBadge from '../Frill/FrillWidget';

export function Navbar() {
  const [defaultWorkspace, setManageWorkspacesToggle] = useWorkspaceStore((state) => [state.defaultWorkspace, state.setManageWorkspaceToggle]);
  const [user] = useUserStore((state) => [state.user]);
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [setBioLink] = useBioLinkSetupStore((state) => [state.setBioLink]);
  // Get account status from workspace
  const isActive = isAccountActive(defaultWorkspace?.organization);
  // Define allowed paths
  const allowedPaths = [
    '/account/billing',
    // '/account/profile',
    '/logout',
    // '/refer&earn',
    '/verification'
  ];

  const MENU_ITEMS = [
    {
      name: 'Home',
      link: `/dashboard/${defaultWorkspace?._id}`,
      icon: faHome,
      disabled: !isActive
    },
    {
      name: 'Links',
      link: `/links/${defaultWorkspace?._id}`,
      icon: faLink,
      disabled: !isActive
    },
    {
      name: 'Campaigns',
      link: `/campaigns/${defaultWorkspace?._id}`,
      icon: faBullhorn,
      disabled: !isActive
    },
    {
      name: 'RSS Feed',
      link: `/rss/${defaultWorkspace?._id}`,
      icon: faRss,
      disabled: !isActive
    },
    {
      name: 'Bio Links',
      link: `/bio-links/${defaultWorkspace?._id}`,
      icon: faLinkHorizontal,
      disabled: !isActive
    },
    {
      name: 'QR Codes',
      link: `/qr-codes/${defaultWorkspace?._id}`,
      icon: faQrcode,
      disabled: !isActive
    },
    {
      name: 'Analytics',
      link: `/analytics/${defaultWorkspace?._id}/short-links`,
      icon: faPieChart,
      disabled: !isActive
    },
    {
      name: 'Reports',
      link: `/reports/${defaultWorkspace?._id}`,
      icon: faFileChartPie,
      disabled: !isActive
    },
  ];

  const logo = defaultWorkspace?.agency?.logo || ReplugLogo;

  const openHelpScout = () => {
    //@ts-ignore
    window?.Beacon('open')
  };


  // Function to check if a path is allowed
  const isPathAllowed = (path: any) => {
    if (isActive) return true;
    return allowedPaths.some(allowedPath => path.includes(allowedPath));
  };

  const getAvatarInitials = () => {
    if (user) {
      // @ts-ignore
      return user.first_name[0] + user.last_name[0];
    }
    return '';
  };
  const navigate = useNavigate();
  return (
    <>
      <Box pb={'sm'}>
        <header className={classes.header}>
          <Group justify="space-between" h="100%">
            <Flex align="center">
              <Link to="" onClick={() => setManageWorkspacesToggle(true)}>
                <Image w={24} h={28} src={logo}
                  alt="Logo"
                />
              </Link>
              <Divider ml={8} color="blue" orientation="vertical" mr={8} />
              <WorkspaceMenu />
            </Flex>

            <Group h="100%" gap={0} visibleFrom="sm">
              {MENU_ITEMS.map((item) => (
                <Tooltip
                  key={item.name}
                  label={item.disabled ? "Please activate your account to access this feature" : ""}
                  disabled={!item.disabled}
                >
                  <Box style={{ cursor: item.disabled ? 'not-allowed' : 'pointer' }}>
                    <NavLink
                      to={item.disabled ? '#' : item.link}
                      onClick={(e) => {
                        if (item.disabled) {
                          e.preventDefault();
                        }
                      }}
                      className={({ isActive, isPending }) =>
                        `${classes.link} ${
                        // Only apply active class if the link is not disabled
                        !item.disabled && isActive ? classes.linkActive : ''
                        } ${item.disabled ? classes.disabledLink : ''}`
                      }
                      style={item.disabled ? { pointerEvents: 'none' } : undefined}
                    >
                      <Flex align={'center'}>
                        <FontAwesomeIcon
                          color="#fff"
                          size="sm"
                          icon={item.icon}
                        />
                        <Text ml="xs" size="sm">
                          {item.name}
                        </Text>
                      </Flex>
                    </NavLink>
                  </Box>
                </Tooltip>
              ))}
            </Group>
            <Group visibleFrom="sm">
              {(defaultPermissions && checkPermission(defaultPermissions, ['add_link', 'add_campaign', 'add_rss', 'add_bio_link', 'add_reports'])) && <Menu withArrow shadow="xs" disabled={!isActive}>
                <Menu.Target>
                  <Tooltip label="Quick actions">
                    <Box
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <FontAwesomeIcon color="#fff" icon={faCirclePlus} />
                    </Box>
                  </Tooltip>
                </Menu.Target>
                <Menu.Dropdown>
                  {(defaultPermissions && checkPermission(defaultPermissions, ['add_link'])) && <Menu.Item onClick={() => navigate(`/links/${defaultWorkspace?._id}?action=create`)}>
                    Create Short Link
                  </Menu.Item>}
                  {(defaultPermissions && checkPermission(defaultPermissions, ['add_campaign'])) && <Menu.Item onClick={() => navigate(`/campaigns/${defaultWorkspace?._id}/setup`)}>
                    Create Campaign
                  </Menu.Item>}
                  {(defaultPermissions && checkPermission(defaultPermissions, ['add_rss'])) && <Menu.Item onClick={() => navigate(`/rss/${defaultWorkspace?._id}?action=create`)}>
                    Create RSS Feed
                  </Menu.Item>}
                  {(defaultPermissions && checkPermission(defaultPermissions, ['add_bio_link'])) && <Menu.Item onClick={() => {
                    setBioLink(DEFUALT_BIO_LINK_VALUES)
                    navigate(`/bio-links/${defaultWorkspace?._id}/setup`)
                  }
                  }>
                    Create Bio Link
                  </Menu.Item>}
                  {(defaultPermissions && checkPermission(defaultPermissions, ['add_reports'])) && <Menu.Item onClick={() => navigate(`/reports/${defaultWorkspace?._id}?action=create`)}>
                    Create Report
                  </Menu.Item>}
                </Menu.Dropdown>
              </Menu>}

              <Divider color="blue" orientation="vertical" />
              <FrillNotificationBadge />
              {(defaultPermissions && checkPermission(defaultPermissions, ['view_apps', 'view_team_member', 'view_integrations', 'view_deep_linking', 'view_cDomains', 'view_whitelabel', 'view_apps', 'view_misc_settings'])) && <Menu shadow="xs" disabled={!isActive}>
                <Menu.Target>
                  <Tooltip label="Workspace Settings">
                    <Box
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <FontAwesomeIcon color="#fff" icon={faGear} />
                    </Box>
                  </Tooltip>
                </Menu.Target>
                <Menu.Dropdown ml={-64}>
                  {(defaultPermissions && checkPermission(defaultPermissions, ['view_misc_settings'])) && <Menu.Item
                    leftSection={<FontAwesomeIcon size="sm" icon={faGear} />}
                    onClick={() => navigate(`/workspace/${defaultWorkspace?._id}/basic`)}
                  >
                    Workspace Settings
                  </Menu.Item>}
                  {(defaultPermissions && checkPermission(defaultPermissions, ['view_team_member'])) && <Menu.Item
                    leftSection={<FontAwesomeIcon size="sm" icon={faUsers} />}
                    onClick={() => navigate(`/workspace/${defaultWorkspace?._id}/team-members`)}
                  >
                    Team Members
                  </Menu.Item>}
                  {(defaultPermissions && checkPermission(defaultPermissions, ['view_integrations'])) && <Menu.Item
                    leftSection={<FontAwesomeIcon size="sm" icon={faNetworkWired} />}
                    onClick={() => navigate(`/workspace/${defaultWorkspace?._id}/integrations`)}
                  >
                    Integrations
                  </Menu.Item>}
                  {(defaultPermissions && checkPermission(defaultPermissions, ['view_deep_linking'])) && <Menu.Item
                    leftSection={<FontAwesomeIcon size="sm" icon={faLink} />}
                    onClick={() => navigate(`/workspace/${defaultWorkspace?._id}/deep-linking`)}
                  >
                    Deep Linking
                  </Menu.Item>}
                  {(defaultPermissions && checkPermission(defaultPermissions, ['view_cDomains'])) && <Menu.Item
                    leftSection={<FontAwesomeIcon size="sm" icon={faGlobe} />}
                    onClick={() => navigate(`/workspace/${defaultWorkspace?._id}/custom-domains`)}
                  >
                    Custom Domains
                  </Menu.Item>}
                  <Menu.Divider />
                  {(defaultPermissions && checkPermission(defaultPermissions, ['view_whitelabel'])) && <Menu.Item
                    leftSection={<FontAwesomeIcon size="sm" icon={faBookmark} />}
                    onClick={() => navigate(`/workspace/${defaultWorkspace?._id}/white-label`)}
                  >
                    White-label
                  </Menu.Item>}
                  {(defaultPermissions && checkPermission(defaultPermissions, ['view_apps'])) && <Menu.Item
                    leftSection={<FontAwesomeIcon size="sm" icon={faCode} />}
                    onClick={() => navigate(`/workspace/${defaultWorkspace?._id}/developer-apps`)}
                  >
                    Developer Apps
                  </Menu.Item>}
                  {(defaultPermissions && checkPermission(defaultPermissions, ['view_misc_settings'])) && <Menu.Item
                    leftSection={<FontAwesomeIcon size="sm" icon={faEllipsisVertical} />}
                    onClick={() => navigate(`/workspace/${defaultWorkspace?._id}/retargeting-pixels`)}
                  >
                    Miscellaneous
                  </Menu.Item>}
                </Menu.Dropdown>
              </Menu>}
              <Menu shadow="xs" >
                <Menu.Target>
                  <Tooltip label="Profile & Billing">
                    <ActionIcon variant="subtle">
                      <Avatar
                        color="white"
                        size="sm"
                        src={user?.profile_image}
                        styles={{
                          root: {
                            border: '1px solid var(--mantine-color-gray-5)',
                          },
                          placeholder: {
                            textTransform: 'uppercase',
                          },
                        }}
                      >
                        {getAvatarInitials()}
                      </Avatar>
                    </ActionIcon>
                  </Tooltip>
                </Menu.Target>
                <Menu.Dropdown ml={-16}>
                  <Menu.Item>
                    <Link to="/account/profile" style={{ textDecoration: 'none', color: 'inherit' }}>
                      <Flex align="center">
                        <Avatar
                          mr={12}
                          size="sm"
                          src={user?.profile_image}
                          color="primary"
                          styles={{
                            placeholder: {
                              textTransform: 'uppercase',
                            },
                          }}
                        >
                          {getAvatarInitials()}
                        </Avatar>
                        <Flex direction="column" pr={12}>
                          <Text pb="1" size="xs">
                            {user?.first_name} {user?.last_name}
                          </Text>
                          <Text pb="1" fz={11}>
                            {user?.email}
                          </Text>
                          <Text c="orange" fz={10} tt="capitalize">
                            {defaultWorkspace?.role?.replace('_', ' ')}
                          </Text>
                        </Flex>
                      </Flex>
                    </Link>
                  </Menu.Item>
                  <Menu.Divider />
                  {(defaultPermissions && checkPermission(defaultPermissions, ['view_whitelabel']) &&
                    defaultWorkspace?.agency?.is_whitelabel_enabled &&
                    <>
                      {(!isWhitelabelDomain()) ?
                        <>
                          <Menu.Item
                            onClick={() => window.open(`https://${defaultWorkspace?.agency?.app_domain}`, '_blank')}
                            leftSection={<FontAwesomeIcon size="sm" icon={faArrowsRotate} />}>
                            Switch to White-label
                          </Menu.Item>
                          <Menu.Divider />
                        </>
                        :
                        <>
                          <Menu.Item
                            onClick={() => window.open(import.meta.env.VITE_APP_URL, '_blank')}
                            leftSection={<FontAwesomeIcon size="sm" icon={faArrowsRotate} />}>
                            Switch to Replug
                          </Menu.Item>
                          <Menu.Divider />
                        </>
                      }
                    </>
                  )}
                  <Menu.Item
                    onClick={() => navigate('/account/profile')}
                    leftSection={<FontAwesomeIcon size="sm" icon={faUser} />}
                  >
                    Your Profile
                  </Menu.Item>

                  {(defaultPermissions && checkPermission(defaultPermissions, ['view_plan'])) && <Menu.Item
                    onClick={() => navigate('/account/billing')}
                    leftSection={<FontAwesomeIcon size="sm" icon={faCreditCard} />}
                  >
                    Billing and Plan
                  </Menu.Item>}
                  {!defaultWorkspace?.agency?.is_whitelabeled &&
                    <>
                      <Menu.Item
                        leftSection={<FontAwesomeIcon size="sm" icon={faMap} />}
                        onClick={() => window.open('https://updates.replug.io/roadmap', '_blank')}
                      >
                        Product Roadmap
                      </Menu.Item>
                      <Menu.Item
                        leftSection={<FontAwesomeIcon size="sm" icon={faBook} />}
                        onClick={() => window.open('https://docs.replug.io', '_blank')}
                      >
                        Knowledgebase
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => navigate('/account/refer&earn')}
                        leftSection={<FontAwesomeIcon size="sm" icon={faDollar} />}
                      >
                        Refer and Earn
                      </Menu.Item>
                      <Menu.Item leftSection={<FontAwesomeIcon size="sm" icon={faQuestionCircle} />} onClick={() => openHelpScout()}>
                        Contact Support
                      </Menu.Item>
                    </>}
                  <Menu.Divider />
                  <Menu.Item
                    leftSection={<FontAwesomeIcon size="sm" icon={faArrowRightFromBracket} />}
                    onClick={() => navigate('/logout')}
                  >
                    Logout
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
          </Group>
        </header>
      </Box>
    </>
  );
}
