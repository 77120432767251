import { ActionIcon, Box, Button, Center, Flex, Grid, Group, Image, Loader, Text, TextInput, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { QRCodeService } from '@/services/QRCodeService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMagnifyingGlass, faPencil, faQrcode, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { notifications } from '@mantine/notifications';
import { modals } from '@mantine/modals';
import { getRelativeTime } from '@/utils/DateUtility';
import { QRCodeCard } from './components/QRCodeCard';

interface QRCode {
    _id: string;
    name: string;
    url: string;
    workspace_id: string;
    created_at: string;
    updated_at: string;
    [key: string]: any; // for any additional properties
}

export function QRCodesPage() {
    const [defaultWorkspace] = useWorkspaceStore(state => [state.defaultWorkspace]);
    const [qrCodes, setQRCodes] = useState<QRCode[]>([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchQRCodes();
    }, [search]);

    const fetchQRCodes = async () => {
        setLoading(true);
        await new QRCodeService()
            .getAll(defaultWorkspace?._id || '', search)
            .then((res) => {
                if (res.data.status) {
                    setQRCodes(res.data.data);
                }
            })
            .catch((err) => console.log(err));
        setLoading(false);
    };

    const confirmDelete = (id: any) => {
        modals.openConfirmModal({
            title: <Title order={5}>Delete QR Code</Title>,
            children: <Text size="sm">Are you sure? This process cannot be undone.</Text>,
            labels: { confirm: 'Delete', cancel: 'Cancel' },
            confirmProps: { color: 'red' },
            onConfirm: () => handleDelete(id),
        });
    };

    const handleDelete = async (id: any) => {
        await new QRCodeService()
            .delete(defaultWorkspace?._id || '', id)
            .then((res) => {
                if (res.data.status) {
                    const updatedQRCodes = qrCodes.filter(qr => qr._id !== id);
                    setQRCodes(updatedQRCodes);
                    notifications.show({
                        color: 'green',
                        message: 'QR code deleted successfully',
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    return (
        <>
            <Flex align="center" pl="lg" pt="xs">
                <Text size="xl" fw="bold">QR Codes</Text>
            </Flex>

            <Grid my="md" px="lg" grow>
                <Grid.Col span={5}>
                    <Flex align="center">
                        <Button
                            size="xs"
                            onClick={() => navigate(`/qr-codes/${defaultWorkspace?._id}/setup`)}
                        >
                            Create New
                        </Button>
                        <TextInput
                            w={160}
                            size="xs"
                            ml={8}
                            value={search}
                            onChange={(e) => setSearch(e.currentTarget.value)}
                            placeholder="Search QR Codes"
                            leftSection={<FontAwesomeIcon icon={faMagnifyingGlass} />}
                        />
                    </Flex>
                </Grid.Col>
            </Grid>

            <Flex direction="column" mx="md" style={{ background: 'var(--mantine-color-white)' }}>
                <Box p="md" mx="md">
                    {loading ? (
                        <Center p="xl"><Loader size="sm" /></Center>
                    ) : qrCodes.length === 0 ? (
                        <Flex justify="center" align="center" direction="column" p="lg">
                            <Text mb="md" fz={14} ta="center">
                                {search ? `No QR codes found for "${search}"` : "You haven't created any QR codes yet."}
                            </Text>
                            <Button onClick={() => navigate(`/qr-codes/${defaultWorkspace?._id}/setup`)}>
                                Create QR Code
                            </Button>
                        </Flex>
                    ) : (
                        <Group style={{ justifyContent: 'center', gap: '30px' }}>
                            {qrCodes.map((qr) => (
                                <QRCodeCard
                                    key={qr._id}
                                    qrCode={qr}
                                    onEdit={() => navigate(`/qr-codes/${defaultWorkspace?._id}/setup/${qr._id}`)}
                                    onDelete={() => confirmDelete(qr._id)}
                                />
                            ))}
                        </Group>
                    )}
                </Box>
            </Flex>
        </>
    );
}