import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { QRCodeService } from '@/services/QRCodeService';
import { LinksService } from '@/services/LinksService';
import { BioLinkService } from '@/services/BioLinkService';
import { notifications } from '@mantine/notifications';
import { useForm } from '@mantine/form';
import {
    Box, Button, ColorInput, Divider, Flex, Group, Image, Paper,
    Select, Text, TextInput, Textarea, Title, useMantineTheme, Tabs, rem, ActionIcon, Tooltip, Menu,
    CopyButton
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faLink, faQrcode, faImage, faFileSvg, faFilePng, faChevronDown, faArrowUpRightFromSquare, faClipboardCheck, faClipboard, faCheck, faTimes, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { getQRAsSVGDataUri, getQRAsCanvas } from '@/utils/qr';
import { getRelativeTime } from '@/utils/DateUtility';
import { useCampaignsStore } from '@/stores/useCampaignsStore';
import { useTagsStore } from '@/stores/useTagsStore';
import { useLinkSetupStore } from '@/stores/useLinkSetupStore';
import { useBioLinkSetupStore } from '@/stores/Bio/useBioLinkSetupStore';
import { LinkCreateTagsSelection } from '@/components/Modals/LinkCreationModal/LinkCreateTagsSelection';
import { appendQrParameter } from '@/utils/LinkUtility';

interface LinkType {
    _id: string;
    url: string;
    short_url: string;
    campaign_id?: string;
    workspace_id?: string;
}

interface SelectedLinkType {
    _id: string;
    short_url: string;
    url?: string;  // Optional for bio links
    campaign_id?: string;
    workspace_id?: string;
    domain_attributes?: {
        url: string;
        scheme?: string;
        netloc?: string;
        registered_domain?: string;
    };
}

export function QRCodeCreateEditPage(): JSX.Element {
    const { id, workspaceId } = useParams<{ id?: string; workspaceId?: string }>();
    const navigate = useNavigate();
    const theme = useMantineTheme();
    const [defaultWorkspace] = useWorkspaceStore(state => [state.defaultWorkspace]);
    const [campaigns] = useCampaignsStore(state => [state.campaigns]);
    const [, setLink] = useLinkSetupStore(state => [state.link, state.setLink]);
    const [, setBioLink] = useBioLinkSetupStore(state => [state.bioLink, state.setBioLink]);
    const [loading, setLoading] = useState<boolean>(false);
    const [sourceType, setSourceType] = useState<string>('new');
    const [links, setLinks] = useState<any[]>([]);
    const [bioLinks, setBioLinks] = useState<any[]>([]);
    const [selectedLink, setSelectedLink] = useState<SelectedLinkType | null>(null);
    const [searchValue, setSearchValue] = useState<string>('');
    const [activeFeatureTab, setActiveFeatureTab] = useState<string>('');
    const [isEditSlug, setIsEditSlug] = useState(false);
    const [editShortURL, setEditShortURL] = useState('');

    const form = useForm({
        initialValues: {
            title: '',
            description: '',
            foreground_color: '#000000',
            background_color: '#FFFFFF',
            campaign_id: '',
            tags: [] as string[],
            link_id: '',
            bio_link_id: '',
            url: '',
            short_url: '',
            custom_link_preview_attributes: {
                title: '',
                description: '',
                image: ''
            },
            summary_page_attributes: {
                title: '',
                description: '',
                image: '',
                button_text: ''
            }
        },
        validate: {
            title: (value: string) => !value ? 'Title is required' : null,
            url: (value: string) => sourceType === 'new' && !value ? 'URL is required' : null,
            link_id: (value: string) => sourceType !== 'new' && !value ? 'Please select a link' : null,
        }
    });

    const isEditMode = !!id;

    useEffect(() => {
        if (isEditMode) {
            fetchQRCodeDetails();
        }
        fetchLinks();
        fetchBioLinks();
    }, [id]);

    // Initialize link setup store whenever form values change
    useEffect(() => {
        setLink({
            tags: form.values.tags || []
        });
    }, [form.values.tags]);

    useEffect(() => {
        // Get source_type and link_id from URL params
        const params = new URLSearchParams(window.location.search);
        const sourceType = params.get('source_type');
        const linkId = params.get('link_id');

        if (sourceType) {
            setSourceType(sourceType);

            // If we have a link_id, fetch and select it
            if (linkId) {
                handleLinkSelect(linkId, sourceType);
            }

            // Get link data from store
            const linkState = useLinkSetupStore.getState();
            const bioLinkState = useBioLinkSetupStore.getState();

            if (sourceType === 'bio' && bioLinkState.bioLink?._id) {
                const bioLink = bioLinkState.bioLink;

                // Set form values from bio link data
                form.setValues({
                    title: '',  // Let user set QR code title
                    description: '', // Let user set QR code description
                    foreground_color: '#000000',
                    background_color: '#FFFFFF',
                    link_id: bioLink._id,
                    short_url: bioLink.short_url,
                    custom_link_preview_attributes: {
                        title: bioLink.meta?.title || bioLink.profile_title,
                        description: bioLink.meta?.description || bioLink.bio_description,
                        image: bioLink.meta?.image || bioLink.profile_image
                    },
                    summary_page_attributes: {
                        title: bioLink.profile_title,
                        description: bioLink.bio_description,
                        image: bioLink.profile_image,
                        button_text: 'Visit Bio Link'
                    }
                });

                // Set selected link with only the required properties
                setSelectedLink({
                    _id: bioLink._id,
                    short_url: bioLink.short_url,
                    workspace_id: bioLink.workspace_id,
                    domain_attributes: bioLink.domain_attributes
                });
                setActiveFeatureTab('customizeSummaryPage');
            } else if (sourceType === 'link' && linkState.link?._id) {
                const link = linkState.link;

                // Set form values from link data
                form.setValues({
                    title: '',  // Let user set QR code title
                    description: '', // Let user set QR code description
                    foreground_color: '#000000',
                    background_color: '#FFFFFF',
                    campaign_id: link.campaign_id || '',
                    tags: link.tags || [],
                    link_id: link._id,
                    url: link.url || '',
                    short_url: link.short_url,
                    custom_link_preview_attributes: {
                        title: link.meta?.title || link.title || '',
                        description: link.meta?.description || link.description || '',
                        image: link.meta?.image || ''
                    },
                    summary_page_attributes: {
                        title: link.title || '',
                        description: link.description || '',
                        image: '',
                        button_text: 'Visit Link'
                    }
                });

                // Set selected link
                setSelectedLink({
                    _id: link._id,
                    short_url: link.short_url,
                    url: link.url,
                    campaign_id: link.campaign_id || undefined,
                    workspace_id: link.workspace_id || undefined,
                    domain_attributes: link.domain_attributes
                });

                // Set source type to 'existing'
                setSourceType('existing');
            }
        }
    }, []);

    const fetchQRCodeDetails = async (): Promise<void> => {
        setLoading(true);
        await new QRCodeService()
            .getById(workspaceId || '', id || '')
            .then((res) => {
                if (res.data.status) {
                    const qrCode = res.data.data;

                    // Get tags from the correct source based on source_type
                    const tags = qrCode.source_type === 'bio'
                        ? qrCode.bio_link?.tags || []
                        : qrCode.link?.tags || [];

                    form.setValues({
                        title: qrCode.title,
                        description: qrCode.description,
                        foreground_color: qrCode.foreground_color,
                        background_color: qrCode.background_color,
                        campaign_id: qrCode.link?.campaign_id || '',
                        tags: tags,
                        link_id: qrCode.source_type === 'bio' ? qrCode.bio_link_id : qrCode.link_id || '',
                        url: qrCode.link?.url || '',
                        short_url: qrCode.link?.short_url || '',
                        custom_link_preview_attributes: qrCode.custom_link_preview_attributes || {},
                        summary_page_attributes: qrCode.summary_page_attributes || {}
                    });

                    // Initialize the link setup store with all necessary data
                    setLink({
                        _id: qrCode._id,
                        tags: tags,
                        campaign_id: qrCode.campaign_id,
                        workspace_id: qrCode.workspace_id,
                        url: qrCode.url,
                        short_url: qrCode.short_url,
                        domain_attributes: qrCode.domain_attributes
                    });

                    setSourceType(qrCode.source_type || 'new');
                    // Set selected link based on source type
                    setSelectedLink(qrCode.source_type === 'bio' ? qrCode.bio_link : qrCode.link);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    };

    const fetchLinks = async (): Promise<void> => {
        await new LinksService()
            .getSelectOptions(workspaceId || '', searchValue)
            .then((res) => {
                if (res.data.status) {
                    setLinks(res.data.data);
                }
            })
            .catch((err) => console.log(err));
    };

    const fetchBioLinks = async (): Promise<void> => {
        await new BioLinkService()
            .getSelectOptions(workspaceId || '', searchValue)
            .then((res) => {
                if (res.data.status) {
                    setBioLinks(res.data.data);
                }
            })
            .catch((err) => console.log(err));
    };

    const handleSubmit = async (values: any): Promise<void> => {
        setLoading(true);
        try {
            // Get the latest tags from the link setup store
            const linkState = useLinkSetupStore.getState();

            const payload = {
                ...values,
                workspace_id: workspaceId,
                source_type: sourceType,
                link_id: sourceType === 'bio' ? null : values.link_id,
                bio_link_id: sourceType === 'bio' ? values.link_id : null,
                // Use tags from both form and link setup store
                tags: linkState.link.tags || values.tags || []
            };

            if (isEditMode) {
                await new QRCodeService()
                    .update(workspaceId || '', id || '', payload)
                    .then((res) => {
                        if (res.data.status) {
                            notifications.show({
                                color: 'green',
                                message: 'QR Code updated successfully',
                            });
                            navigate(`/qr-codes/${workspaceId}`);
                        }
                    });
            } else {
                await new QRCodeService()
                    .create(workspaceId || '', payload)
                    .then((res) => {
                        if (res.data.status) {
                            notifications.show({
                                color: 'green',
                                message: 'QR Code created successfully',
                            });
                            navigate(`/qr-codes/${workspaceId}`);
                        }
                    });
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            notifications.show({
                color: 'red',
                message: 'Error saving QR Code',
            });
        } finally {
            setLoading(false);
        }
    };

    const handleLinkSelect = async (linkId: string, type: string): Promise<void> => {
        if (!linkId) {
            setSelectedLink(null);
            form.setFieldValue('link_id', '');
            return;
        }

        setLoading(true);
        try {
            let response;
            if (type === 'link') {
                response = await new LinksService().getById(workspaceId || '', linkId);
            } else {
                response = await new BioLinkService().getById(workspaceId || '', linkId);
            }

            if (response.data.status) {
                const link = response.data.data;
                setSelectedLink(link);
                form.setFieldValue('link_id', link._id);
                form.setFieldValue('campaign_id', link.campaign_id || '');
                form.setFieldValue('url', link.url || '');
                form.setFieldValue('short_url', link.short_url || '');
                form.setFieldValue('tags', link.tags || []);

                // Update link setup store with tags
                setLink({
                    ...link,
                    tags: link.tags || []
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSourceTypeChange = (value: string | null) => {
        if (!value) return;

        // Only reset fields if we're not in edit mode
        if (!isEditMode) {
            form.setValues({
                ...form.values,
                link_id: '',
                url: '',
                short_url: '',
                campaign_id: '',
                tags: [],
                custom_link_preview_attributes: {
                    title: '',
                    description: '',
                    image: ''
                },
                summary_page_attributes: {
                    title: '',
                    description: '',
                    image: '',
                    button_text: ''
                }
            });

            // Reset selected link
            setSelectedLink(null);
        }

        setSourceType(value);
    };

    const handleEditShortenURL = async () => {
        if (!selectedLink?._id) {
            notifications.show({
                color: 'red',
                message: 'No link selected',
            });
            return;
        }

        if (sourceType === 'bio') {
            // Use bio link endpoint
            await new BioLinkService()
                .update(defaultWorkspace?._id || '', {
                    ...selectedLink,
                    short_url: editShortURL,
                    short_url_lowercase: editShortURL.toLowerCase()
                })
                .then((res) => {
                    if (res.data.status) {
                        // Bio link returns complete updated data
                        setSelectedLink(res.data.data);
                        // Only update the short_url in the form
                        form.setFieldValue('short_url', res.data.data.short_url);
                        setIsEditSlug(false);
                        notifications.show({
                            color: 'green',
                            message: 'URL updated successfully',
                        });
                    }
                })
                .catch((err) => {
                    notifications.show({
                        color: 'red',
                        message: err.response?.data?.message || 'Error updating URL',
                    });
                });
        } else {
            // Use link endpoint
            await new LinksService()
                .editShortenURL(defaultWorkspace?._id || '', {
                    id: selectedLink._id,
                    short_url: editShortURL
                })
                .then((res) => {
                    if (res.data.status) {
                        // Link endpoint only returns success status, manually update the UI
                        const updatedLink = {
                            ...selectedLink,
                            short_url: editShortURL,
                            short_url_lowercase: editShortURL.toLowerCase()
                        };
                        setSelectedLink(updatedLink);
                        // Only update the short_url in the form
                        form.setFieldValue('short_url', editShortURL);
                        setIsEditSlug(false);
                        notifications.show({
                            color: 'green',
                            message: 'URL updated successfully',
                        });
                    }
                })
                .catch((err) => {
                    notifications.show({
                        color: 'red',
                        message: err.response?.data?.message || 'Error updating URL',
                    });
                });
        }
    };

    useEffect(() => {
        return () => {
            setIsEditSlug(false);
            setEditShortURL('');
        };
    }, []);

    return (
        <Box>
            <Flex gap="md" h="100vh">
                {/* Main Content */}
                <Box style={{ flex: 1, overflow: 'auto' }} p="md">
                    <Flex justify="left" align="center" mb="lg" bg="white" p="md" style={{ borderBottom: `1px solid ${theme.colors.gray[2]}` }}>
                        <Button
                            variant="subtle"
                            leftSection={<FontAwesomeIcon icon={faArrowLeft} />}
                            onClick={() => navigate(-1)}
                        >
                            Back
                        </Button>

                        {(selectedLink || form.values.url) && (
                            <Group align="center">
                                <Divider orientation="vertical" />
                                <TextInput
                                    w={550}
                                    size="sm"
                                    placeholder="Your QR code URL"
                                    disabled={!isEditSlug}
                                    leftSectionWidth={
                                        selectedLink?.domain_attributes?.url
                                            ? selectedLink.domain_attributes.url.length * 8.1 + 24
                                            : 12 + 24
                                    }
                                    leftSection={
                                        <Flex align={'center'}>
                                            <FontAwesomeIcon icon={faLink} />
                                            <Text ml={12} fz={15}>
                                                {selectedLink?.domain_attributes?.url || 'http://localhost:3000/'}
                                            </Text>
                                        </Flex>
                                    }
                                    value={isEditSlug ? editShortURL : (selectedLink ? selectedLink.short_url : form.values.short_url)}
                                    onChange={(event) => {
                                        if (isEditSlug) {
                                            setEditShortURL(event.currentTarget.value);
                                        }
                                    }}
                                    rightSectionWidth={isEditSlug ? 80 : 120}
                                    rightSection={
                                        <>
                                            {isEditSlug ? (
                                                <>
                                                    <Tooltip label="Save">
                                                        <ActionIcon
                                                            variant="subtle"
                                                            color="gray.6"
                                                            onClick={async () => {
                                                                if (sourceType === 'bio') {
                                                                    await new BioLinkService()
                                                                        .update(defaultWorkspace?._id || '', {
                                                                            ...selectedLink,
                                                                            short_url: editShortURL,
                                                                            short_url_lowercase: editShortURL.toLowerCase()
                                                                        })
                                                                        .then((res) => {
                                                                            if (res.data.status) {
                                                                                setSelectedLink(res.data.data);
                                                                                // Only update the short_url in the form
                                                                                form.setFieldValue('short_url', res.data.data.short_url);
                                                                                setIsEditSlug(false);
                                                                                notifications.show({
                                                                                    color: 'green',
                                                                                    message: 'URL updated successfully',
                                                                                });
                                                                            }
                                                                        })
                                                                        .catch((err) => {
                                                                            notifications.show({
                                                                                color: 'red',
                                                                                message: err.response?.data?.message || 'Error updating URL',
                                                                            });
                                                                        });
                                                                } else {
                                                                    await new LinksService()
                                                                        .editShortenURL(defaultWorkspace?._id || '', {
                                                                            id: selectedLink?._id || '',
                                                                            short_url: editShortURL
                                                                        })
                                                                        .then((res) => {
                                                                            if (res.data.status) {
                                                                                // Link endpoint only returns success status, manually update the UI
                                                                                const updatedLink = {
                                                                                    ...selectedLink,
                                                                                    short_url: editShortURL,
                                                                                    short_url_lowercase: editShortURL.toLowerCase()
                                                                                };
                                                                                setSelectedLink(updatedLink as SelectedLinkType | null);
                                                                                // Only update the short_url in the form
                                                                                form.setFieldValue('short_url', editShortURL);
                                                                                setIsEditSlug(false);
                                                                                notifications.show({
                                                                                    color: 'green',
                                                                                    message: 'URL updated successfully',
                                                                                });
                                                                            }
                                                                        })
                                                                        .catch((err) => {
                                                                            notifications.show({
                                                                                color: 'red',
                                                                                message: err.response?.data?.message || 'Error updating URL',
                                                                            });
                                                                        });
                                                                }
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faCheck}
                                                                style={{
                                                                    color: theme.colors.green[6],
                                                                }}
                                                            />
                                                        </ActionIcon>
                                                    </Tooltip>
                                                    <Tooltip label="Cancel">
                                                        <ActionIcon
                                                            variant="subtle"
                                                            color="gray.6"
                                                            onClick={() => {
                                                                setEditShortURL('');
                                                                setIsEditSlug(false);
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faTimes}
                                                                style={{
                                                                    color: theme.colors.red[6],
                                                                }}
                                                            />
                                                        </ActionIcon>
                                                    </Tooltip>
                                                </>
                                            ) : (
                                                <>
                                                    <Tooltip label="Edit Slug">
                                                        <ActionIcon
                                                            variant="subtle"
                                                            color="gray.6"
                                                            onClick={() => {
                                                                setEditShortURL(selectedLink ? selectedLink.short_url : form.values.short_url);
                                                                setIsEditSlug(true);
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faPencil} />
                                                        </ActionIcon>
                                                    </Tooltip>
                                                    <Tooltip label="Open link">
                                                        <ActionIcon
                                                            variant="subtle"
                                                            color="gray.6"
                                                            onClick={() => {
                                                                const url = selectedLink?.domain_attributes?.url || 'http://localhost:3000/';
                                                                const shortUrl = selectedLink ? selectedLink.short_url : form.values.short_url;
                                                                window.open(`${url}${shortUrl}`, '_blank');
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                                                        </ActionIcon>
                                                    </Tooltip>

                                                    <CopyButton value={`${selectedLink?.domain_attributes?.url || 'http://localhost:3000/'}${selectedLink ? selectedLink.short_url : form.values.short_url}`}>
                                                        {({ copied, copy }) => (
                                                            <Tooltip label="Copy link">
                                                                <ActionIcon
                                                                    mr={'lg'}
                                                                    variant="subtle"
                                                                    color={copied ? 'teal' : 'gray.6'}
                                                                    onClick={copy}
                                                                >
                                                                    {copied ? (
                                                                        <FontAwesomeIcon icon={faClipboardCheck} />
                                                                    ) : (
                                                                        <FontAwesomeIcon icon={faClipboard} />
                                                                    )}
                                                                </ActionIcon>
                                                            </Tooltip>
                                                        )}
                                                    </CopyButton>
                                                </>
                                            )}
                                        </>
                                    }
                                />
                            </Group>
                        )}
                    </Flex>

                    <Flex gap="md" wrap="wrap" align="flex-start">
                        {/* Left Section - Form */}
                        <Box style={{ flex: '1 1 calc(66.67% - 8px)', minWidth: '500px' }}>
                            {/* Details Section */}
                            <Paper p="md" radius="md" withBorder mb="md">
                                <Title order={4} mb="md">Details</Title>
                                <TextInput
                                    label="Title"
                                    placeholder="Add a title..."
                                    {...form.getInputProps('title')}
                                    mb="md"
                                />

                                <Textarea
                                    label="Description"
                                    placeholder="Add a description..."
                                    {...form.getInputProps('description')}
                                />
                            </Paper>

                            {/* QR Link Section */}
                            <Paper p="md" radius="md" withBorder mb="md">
                                <Title order={4} mb="md">QR Link</Title>
                                <Box mb="md">
                                    <Tabs
                                        value={sourceType}
                                        onChange={handleSourceTypeChange}
                                        variant="pills"
                                    >
                                        <Tabs.List mb="md" style={{ gap: '8px', display: 'flex' }}>
                                            {(!isEditMode || (isEditMode && sourceType === 'new')) && (
                                                <Tabs.Tab
                                                    value="new"
                                                    leftSection={<FontAwesomeIcon icon={faLink} style={{ color: sourceType === 'new' ? 'white' : '#003aa9' }} />}
                                                    style={{
                                                        backgroundColor: sourceType === 'new' ? '#003aa9' : '#f1f3f5',
                                                        color: sourceType === 'new' ? 'white' : '#003aa9',
                                                        borderRadius: '25px',
                                                        padding: '8px 16px'
                                                    }}
                                                >
                                                    Create New
                                                </Tabs.Tab>
                                            )}
                                            {(!isEditMode || (isEditMode && sourceType === 'link')) && (
                                                <Tabs.Tab
                                                    value="link"
                                                    leftSection={<FontAwesomeIcon icon={faQrcode} style={{ color: sourceType === 'link' ? 'white' : '#003aa9' }} />}
                                                    style={{
                                                        backgroundColor: sourceType === 'link' ? '#003aa9' : '#f1f3f5',
                                                        color: sourceType === 'link' ? 'white' : '#003aa9',
                                                        borderRadius: '25px',
                                                        padding: '8px 16px'
                                                    }}
                                                >
                                                    Existing Link
                                                </Tabs.Tab>
                                            )}
                                            {(!isEditMode || (isEditMode && sourceType === 'bio')) && (
                                                <Tabs.Tab
                                                    value="bio"
                                                    leftSection={<FontAwesomeIcon icon={faImage} style={{ color: sourceType === 'bio' ? 'white' : '#003aa9' }} />}
                                                    style={{
                                                        backgroundColor: sourceType === 'bio' ? '#003aa9' : '#f1f3f5',
                                                        color: sourceType === 'bio' ? 'white' : '#003aa9',
                                                        borderRadius: '25px',
                                                        padding: '8px 16px'
                                                    }}
                                                >
                                                    Existing Bio Link
                                                </Tabs.Tab>
                                            )}
                                        </Tabs.List>

                                        <Paper p="lg" withBorder style={{ borderColor: theme.colors.gray[3] }}>
                                            <Tabs.Panel value="new">
                                                <Flex direction="column" gap="md">
                                                    <Flex gap="md">
                                                        <Box style={{ flex: 1, minWidth: '250px' }}>
                                                            <TextInput
                                                                label="Destination URL"
                                                                placeholder="Enter URL..."
                                                                {...form.getInputProps('url')}
                                                            />
                                                        </Box>
                                                    </Flex>
                                                    <Flex gap="md">
                                                        <Box style={{ flex: 1, minWidth: '250px' }}>
                                                            <Select
                                                                label="Campaign"
                                                                placeholder="Select campaign..."
                                                                data={campaigns.map(campaign => ({
                                                                    value: campaign._id,
                                                                    label: campaign.name
                                                                }))}
                                                                {...form.getInputProps('campaign_id')}
                                                            />
                                                        </Box>
                                                        <Box style={{ flex: 1, minWidth: '250px' }}>
                                                            <LinkCreateTagsSelection />
                                                        </Box>
                                                    </Flex>
                                                </Flex>
                                            </Tabs.Panel>

                                            <Tabs.Panel value="link">
                                                <Flex direction="column" gap="md">
                                                    <Flex gap="md">
                                                        <Box style={{ flex: 1, minWidth: '250px' }}>
                                                            <Select
                                                                label="Select Link"
                                                                placeholder="Choose a link..."
                                                                data={links}
                                                                searchable
                                                                {...form.getInputProps('link_id')}
                                                                onChange={(value) => handleLinkSelect(value || '', 'link')}
                                                            />
                                                        </Box>
                                                        {selectedLink && (
                                                            <Box style={{ flex: 1, minWidth: '250px' }}>
                                                                <TextInput
                                                                    label="Destination URL"
                                                                    value={selectedLink.url}
                                                                    readOnly
                                                                />
                                                            </Box>
                                                        )}
                                                    </Flex>
                                                    <Flex gap="md">
                                                        <Box style={{ flex: 1, minWidth: '250px' }}>
                                                            <LinkCreateTagsSelection />
                                                        </Box>
                                                        <Box style={{ flex: 1, minWidth: '250px' }} /> {/* Empty box to maintain layout */}
                                                    </Flex>
                                                </Flex>
                                            </Tabs.Panel>

                                            <Tabs.Panel value="bio">
                                                <Flex gap="md">
                                                    <Box style={{ flex: 1, minWidth: '250px' }}>
                                                        <Select
                                                            label="Select Bio Link"
                                                            placeholder="Choose a bio link..."
                                                            data={bioLinks}
                                                            searchable
                                                            {...form.getInputProps('link_id')}
                                                            onChange={(value: any) => {
                                                                form.setFieldValue('link_id', value);
                                                                handleLinkSelect(value || '', 'bio');
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box style={{ flex: 1, minWidth: '250px' }} /> {/* Empty box to maintain layout */}
                                                </Flex>
                                            </Tabs.Panel>
                                        </Paper>
                                    </Tabs>
                                </Box>
                            </Paper>

                            {/* Customization Section */}
                            <Paper p="md" radius="md" withBorder>
                                <Title order={4} mb="md">Customization</Title>
                                <Group grow mb="md">
                                    <ColorInput
                                        label="Foreground Color"
                                        {...form.getInputProps('foreground_color')}
                                    />
                                    <ColorInput
                                        label="Background Color"
                                        {...form.getInputProps('background_color')}
                                    />
                                </Group>
                            </Paper>
                        </Box>

                        {/* Right Section - Preview */}
                        <Box style={{ flex: '1 1 calc(33.33% - 8px)', minWidth: '300px' }}>
                            <Paper p="md" radius="md" withBorder pt="xl">
                                <Title order={4} mb="md" style={{ textAlign: 'center' }}>QR Code Preview</Title>
                                <Box mb="md" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Paper
                                        radius="lg"
                                        style={{
                                            backgroundColor: 'white',
                                            boxShadow: '0 5px 15px rgba(0,0,0,0.1)',
                                            width: 'fit-content',
                                            margin: '0px 20px 20px 20px'
                                        }}
                                    >
                                        <Image
                                            src={getQRAsSVGDataUri({
                                                value: sourceType === 'bio' ?
                                                    `${selectedLink?.domain_attributes?.url || 'http://localhost:3000/'}${selectedLink?.short_url || ''}` :
                                                    sourceType === 'link' ?
                                                        `${selectedLink?.domain_attributes?.url || 'http://localhost:3000/'}${selectedLink?.short_url || ''}` :
                                                        form.values.url || "",
                                                fgColor: form.values.foreground_color,
                                                bgColor: form.values.background_color
                                            })}
                                            alt="QR Code Preview"
                                            style={{ width: '300px', height: '300px' }}
                                        />
                                    </Paper>
                                </Box>

                                {/* Action Buttons */}
                                <Box style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '1rem' }}>
                                    <Group align="center">
                                        <Button
                                            loading={loading}
                                            color="#003aa9"
                                            size="sm"
                                            style={{ width: '180px' }}
                                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                                e.preventDefault();
                                                form.onSubmit(handleSubmit)();
                                            }}
                                        >
                                            {isEditMode ? 'Update' : 'Create QR Code'}
                                        </Button>

                                        {/* Download Button with Dropdown */}
                                        {(isEditMode || form.values.url || selectedLink) && (
                                            //@ts-ignore
                                            <Menu position="bottom-end" px="xl">
                                                <Menu.Target>
                                                    <Button
                                                        variant="light"
                                                        color="gray"
                                                        size="sm"
                                                        rightSection={<FontAwesomeIcon icon={faChevronDown} />}
                                                    >
                                                        Download
                                                    </Button>
                                                </Menu.Target>

                                                <Menu.Dropdown>
                                                    <Menu.Item
                                                        leftSection={<FontAwesomeIcon icon={faFileSvg} />}
                                                        onClick={() => {
                                                            const link = document.createElement('a');
                                                            link.download = `${form.values.title || 'qr-code'}.svg`;
                                                            const baseUrl = sourceType === 'bio' ?
                                                                `${selectedLink?.domain_attributes?.url || 'http://localhost:3000/'}${selectedLink?.short_url || ''}` :
                                                                sourceType === 'link' ?
                                                                    `${selectedLink?.domain_attributes?.url || 'http://localhost:3000/'}${selectedLink?.short_url || ''}` :
                                                                    form.values.url || "";
                                                            link.href = getQRAsSVGDataUri({
                                                                value: appendQrParameter(baseUrl),
                                                                fgColor: form.values.foreground_color,
                                                                bgColor: form.values.background_color
                                                            });
                                                            link.click();
                                                        }}
                                                    >
                                                        Download as SVG
                                                    </Menu.Item>

                                                    <Menu.Item
                                                        leftSection={<FontAwesomeIcon icon={faFilePng} />}
                                                        onClick={async () => {
                                                            const baseUrl = sourceType === 'bio' ?
                                                                `${selectedLink?.domain_attributes?.url || 'http://localhost:3000/'}${selectedLink?.short_url || ''}` :
                                                                sourceType === 'link' ?
                                                                    `${selectedLink?.domain_attributes?.url || 'http://localhost:3000/'}${selectedLink?.short_url || ''}` :
                                                                    form.values.url || "";

                                                            const dataUrl = await getQRAsCanvas({
                                                                value: appendQrParameter(baseUrl),
                                                                fgColor: form.values.foreground_color,
                                                                bgColor: form.values.background_color
                                                            }, 'image/png');

                                                            const link = document.createElement('a');
                                                            link.download = `${form.values.title || 'qr-code'}.png`;
                                                            link.href = dataUrl as string;
                                                            link.click();
                                                        }}
                                                    >
                                                        Download as PNG
                                                    </Menu.Item>
                                                </Menu.Dropdown>
                                            </Menu>
                                        )}
                                    </Group>
                                </Box>
                            </Paper>
                        </Box>
                    </Flex>
                </Box>
            </Flex>
        </Box>
    );
}