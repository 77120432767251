import { API_URL } from '@/constants/Constants';
import axios from './JWTService';

export class QRCodeService {
    getAll(workspaceId: string, search: string = '') {
        return axios.get(`${API_URL}/workspace/${workspaceId}/qr-code?search=${search}`);
    }

    getById(workspaceId: string, id: string) {
        return axios.get(`${API_URL}/workspace/${workspaceId}/qr-code/${id}`);
    }

    create(workspaceId: string, data: any) {
        return axios.post(`${API_URL}/workspace/${workspaceId}/qr-code`, data);
    }

    update(workspaceId: string, id: string, data: any) {
        return axios.put(`${API_URL}/workspace/${workspaceId}/qr-code/${id}`, data);
    }

    delete(workspaceId: string, id: string) {
        return axios.delete(`${API_URL}/workspace/${workspaceId}/qr-code/${id}`);
    }

    // Helper methods for the LinksService and BioLinkService
    getSelectOptions(workspaceId: string, search: string = '') {
        return axios.get(`${API_URL}/workspace/${workspaceId}/link/select-options?search=${search}`);
    }
}