import { LinksService } from '@/services/LinksService';
import { useCampaignsStore } from '@/stores/useCampaignsStore';
import { useLinkSetupStore } from '@/stores/useLinkSetupStore';
import { Box, Select } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';

interface LinkCampaignSelection {
  onCampaignUpdate: (newValue: boolean) => void;
}

export const LinkCampaignSelection: React.FC<LinkCampaignSelection> = ({ onCampaignUpdate }) => {
  const [link, setLink] = useLinkSetupStore((state) => [state.link, state.setLink]);
  const [campaigns] = useCampaignsStore((state) => [state.campaigns]);
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);

  const updateCampaign = (value: string | null) => {
    onCampaignUpdate(true)
    new LinksService()
      .updateCampaign(defaultWorkspace?._id || '', link._id, value || '')
      .then((res) => {
        if (res.data.status) {
          setLink({
            ...link,
            campaign_id: value,
            domain_attributes: res.data.data.domain_attributes,
          });
          notifications.show({
            color: 'green',
            message: 'Campaign updated for the link successfully.',
          });
          onCampaignUpdate(false)
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.response.status === 400 || err.response.status === 422) {
          notifications.show({
            color: 'red',
            message: err.response.data.message,
          });
          onCampaignUpdate(false)
        }
      });
  };

  return (
    <Box w="100%">
      <Select
        allowDeselect={false}
        searchable
        value={link.campaign_id}
        data={campaigns.map((campaign: any) => ({
          label: campaign.name,
          value: campaign._id,
        }))}
        label="Campaign"
        placeholder="Select your campaign"
        onChange={(value) => updateCampaign(value)}
      />
    </Box>
  );
};
