// QRAnalyticsByLocation.tsx
import ReactEChartsCore from 'echarts-for-react/lib/core';
// @ts-ignore
import * as echarts from 'echarts/dist/echarts.esm';
import {
    Box,
    Divider,
    Flex,
    Loader,
    ScrollArea,
    SegmentedControl,
    Table,
    Text,
} from '@mantine/core';
import { AnalyticsQRService } from '@/services/AnalyticsQRService';
import { useEffect, useState } from 'react';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import ReactCountryFlag from 'react-country-flag';
import { numberToCommas } from '@/utils/StringUtility';
import { useLocation } from 'react-router-dom';
import { NoResultsListTable } from '@/components/Menus/NoResultsListTable/NoResultsListTable';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';

export const QRAnalyticsByLocation = () => {
    // Get Values from Query Params
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const fromDate =
        queryParams.get('from_date') ||
        format(startOfDay(subDays(new Date(), 30)), "yyyy-MM-dd'T'HH:mm:ss");
    const toDate =
        queryParams.get('to_date') || format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss");
    const linkId = queryParams.get('link_id');

    const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [type, setType] = useState('country');

    const fetchLocation = async () => {
        setLoading(true);
        await new AnalyticsQRService()
            .location(defaultWorkspace?._id || '', type, linkId, fromDate, toDate)
            .then((res) => {
                if (res.data.status && res.data.data.length > 0) {
                    setList(res.data.data);
                } else {
                    setList([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        setLoading(false);
    };

    const renderCountryColumn = (item: any) => {
        return (
            <Flex align={'center'}>
                <ReactCountryFlag
                    countryCode={item.country_code}
                    svg
                    style={{
                        borderRadius: 4,
                        width: '1rem',
                        height: '1rem',
                        marginRight: 8,
                    }}
                    title={item.country_name}
                />
                {item.country_name}
            </Flex>
        );
    };

    const renderCityColumn = (item: any) => {
        return (
            <Flex align={'center'}>
                <ReactCountryFlag
                    countryCode={item.country_code}
                    svg
                    style={{
                        borderRadius: 4,
                        width: '1rem',
                        height: '1rem',
                        marginRight: 8,
                    }}
                    title={item.country_name}
                />
                {item.city}
            </Flex>
        );
    };

    const renderRegionColumn = (item: any) => {
        return (
            <Flex align={'center'}>
                <ReactCountryFlag
                    countryCode={item.country_code}
                    svg
                    style={{
                        borderRadius: 4,
                        width: '1rem',
                        height: '1rem',
                        marginRight: 8,
                    }}
                    title={item.country_name}
                />
                {item.region}
            </Flex>
        );
    };

    useEffect(() => {
        fetchLocation();
    }, [type, linkId, fromDate, toDate]);

    return (
        <>
            <Box
                bg={'white'}
                h={480}
                style={{
                    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
                    border: '1px solid var(--mantine-color-gray-2)',
                    borderRadius: 4,
                }}
            >
                <Flex align={'center'} p={12}>
                    <Text
                        style={{
                            flexGrow: 1,
                        }}
                        fw={600}
                    >
                        By Location
                    </Text>
                    <Flex>
                        <SegmentedControl
                            size="xs"
                            value={type}
                            onChange={setType}
                            data={[
                                { label: 'Country', value: 'country' },
                                { label: 'City', value: 'city' },
                                { label: 'Region', value: 'region' },
                            ]}
                        />
                    </Flex>
                </Flex>
                <Divider />

                {loading ? (
                    <>
                        <Flex mt={64} align={'center'} justify={'center'}>
                            <Loader size="sm" />
                        </Flex>
                    </>
                ) : (
                    <>
                        {list.length === 0 ? (
                            <Box pt={96}>
                                <NoResultsListTable message={'No location data available'} />
                            </Box>
                        ) : (
                            <>
                                <ScrollArea h={380} scrollbarSize={6}>
                                    <Table
                                        stickyHeader
                                        striped
                                        verticalSpacing={'xs'}
                                        style={{
                                            borderLeft: '1px solid var(--mantine-color-gray-2)',
                                        }}
                                    >
                                        <Table.Thead h={4} fz="xs">
                                            <Table.Tr>
                                                <Table.Th w={'40%'}>
                                                    <></>
                                                </Table.Th>
                                                <Table.Th ta="center">Total Clicks</Table.Th>
                                                <Table.Th ta="center">Unique Clicks</Table.Th>
                                                <Table.Th ta="center">QR Scans</Table.Th>
                                            </Table.Tr>
                                        </Table.Thead>
                                        <Table.Tbody>
                                            {list.map((item: any) => (
                                                <Table.Tr key={item.country_code + (item.city || item.region || '')}>
                                                    <Table.Td w={'40%'}>
                                                        {type === 'country' ? (
                                                            <>{renderCountryColumn(item)}</>
                                                        ) : type === 'city' ? (
                                                            <>{renderCityColumn(item)}</>
                                                        ) : (
                                                            <>{renderRegionColumn(item)}</>
                                                        )}
                                                    </Table.Td>
                                                    <Table.Td ta="center">{numberToCommas(item.clicks)}</Table.Td>
                                                    <Table.Td ta="center">{numberToCommas(item.unique_clicks)}</Table.Td>
                                                    <Table.Td ta="center">{numberToCommas(item.qr_scans)}</Table.Td>
                                                </Table.Tr>
                                            ))}
                                        </Table.Tbody>
                                    </Table>
                                </ScrollArea>
                            </>
                        )}
                    </>
                )}
            </Box>
        </>
    );
};