// QRAnalyticsOverview.tsx
import { AnalyticsQRService } from '@/services/AnalyticsQRService';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { numberToCommas, numberToUnit } from '@/utils/StringUtility';
import { faArrowDown, faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, Text, Flex, Title, Skeleton } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';

const DEFAULT_VALUES = {
    current: {
        clicks: 0,
        unique_clicks: 0,
        qr_scans: 0,
    },
    growth: {
        clicks: 0,
        unique_clicks: 0,
        qr_scans: 0,
    },
};

export const QRAnalyticsOverview = () => {
    // Get Values from Query Params
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const fromDate =
        queryParams.get('from_date') ||
        format(startOfDay(subDays(new Date(), 30)), "yyyy-MM-dd'T'HH:mm:ss");
    const toDate =
        queryParams.get('to_date') || format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss");
    const linkId = queryParams.get('link_id');

    const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<{
        current: {
            clicks: number;
            unique_clicks: number;
            qr_scans: number;
        };
        growth: {
            clicks: number;
            unique_clicks: number;
            qr_scans: number;
        };
    }>(DEFAULT_VALUES);

    const fetchAnalyticsOverview = async () => {
        setLoading(true);
        await new AnalyticsQRService()
            .overview(defaultWorkspace?._id || '', linkId, fromDate, toDate)
            .then((res) => {
                if (res.data.status) {
                    setData(res.data.data);
                } else {
                    setData(DEFAULT_VALUES);
                }
            })
            .catch((err) => {
                setData(DEFAULT_VALUES);
            });
        setLoading(false);
    };

    useEffect(() => {
        fetchAnalyticsOverview();
    }, [linkId, fromDate, toDate]);

    return (
        <>
            <Grid gutter={'md'}>
                <Grid.Col span={4} h={150} mb={12}>
                    <Box
                        bg={'white'}
                        style={{
                            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
                            border: '1px solid var(--mantine-color-gray-2)',
                            borderRadius: 4,
                            padding: 16,
                        }}
                    >
                        <Text fw={600} c="dimmed" mb={16}>
                            QR Scans
                        </Text>
                        {loading ? (
                            <>
                                <Skeleton height={67} radius="sm" />
                            </>
                        ) : (
                            <>
                                <Title order={3} mb={16}>
                                    {numberToUnit(data.current.qr_scans)}
                                </Title>
                                <Flex align={'center'}>
                                    {data.growth.qr_scans !== 0 && (
                                        <FontAwesomeIcon
                                            icon={data.growth.qr_scans > 0 ? faArrowUp : faArrowDown}
                                            size="xs"
                                            style={{
                                                color:
                                                    data.growth.qr_scans > 0
                                                        ? 'var(--mantine-color-green-7)'
                                                        : 'var(--mantine-color-red-7)',
                                            }}
                                        />
                                    )}

                                    <Text fz={13} c="green.7" ml={8}>
                                        {numberToUnit(data.growth.qr_scans)}% from last period
                                    </Text>
                                </Flex>
                            </>
                        )}
                    </Box>
                </Grid.Col>

                <Grid.Col span={4} h={150}>
                    <Box
                        bg={'white'}
                        style={{
                            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
                            border: '1px solid var(--mantine-color-gray-2)',
                            borderRadius: 4,
                            padding: 16,
                        }}
                    >
                        <Text fw={600} c="dimmed" mb={16}>
                            Total Clicks
                        </Text>
                        {loading ? (
                            <>
                                <Skeleton height={67} radius="sm" />
                            </>
                        ) : (
                            <>
                                <Title order={3} mb={16}>
                                    {numberToUnit(data.current.clicks)}
                                </Title>
                                <Flex align={'center'}>
                                    {data.growth.clicks !== 0 && (
                                        <>
                                            <FontAwesomeIcon
                                                icon={data.growth.clicks > 0 ? faArrowUp : faArrowDown}
                                                size="xs"
                                                style={{
                                                    color:
                                                        data.growth.clicks > 0
                                                            ? 'var(--mantine-color-green-7)'
                                                            : 'var(--mantine-color-red-7)',
                                                }}
                                            />
                                        </>
                                    )}

                                    <Text fz={13} c="green.7" ml={8}>
                                        {numberToUnit(data.growth.clicks)}% from last period
                                    </Text>
                                </Flex>
                            </>
                        )}
                    </Box>
                </Grid.Col>
                <Grid.Col span={4} h={150} mb={12}>
                    <Box
                        bg={'white'}
                        style={{
                            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
                            border: '1px solid var(--mantine-color-gray-2)',
                            borderRadius: 4,
                            padding: 16,
                        }}
                    >
                        <Text fw={600} c="dimmed" mb={16}>
                            Unique Clicks
                        </Text>

                        {loading ? (
                            <>
                                <Skeleton height={67} radius="sm" />
                            </>
                        ) : (
                            <>
                                <Title order={3} mb={16}>
                                    {numberToUnit(data.current.unique_clicks)}
                                </Title>
                                <Flex align={'center'}>
                                    {data.growth.unique_clicks !== 0 && (
                                        <FontAwesomeIcon
                                            icon={data.growth.unique_clicks > 0 ? faArrowUp : faArrowDown}
                                            size="xs"
                                            style={{
                                                color:
                                                    data.growth.unique_clicks > 0
                                                        ? 'var(--mantine-color-green-7)'
                                                        : 'var(--mantine-color-red-7)',
                                            }}
                                        />
                                    )}
                                    <Text fz={13} c="green.7" ml={8}>
                                        {numberToUnit(data.growth.unique_clicks)}% from last period
                                    </Text>
                                </Flex>
                            </>
                        )}
                    </Box>
                </Grid.Col>
            </Grid>
        </>
    );
};