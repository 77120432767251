// QRAnalyticsByDevice.tsx
import ReactEChartsCore from 'echarts-for-react/lib/core';
// @ts-ignore
import * as echarts from 'echarts/dist/echarts.esm';
import {
    Box,
    Divider,
    Flex,
    Loader,
    ScrollArea,
    SegmentedControl,
    Table,
    Text,
} from '@mantine/core';
import { numberToCommas } from '@/utils/StringUtility';
import { AnalyticsQRService } from '@/services/AnalyticsQRService';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NoResultsListTable } from '@/components/Menus/NoResultsListTable/NoResultsListTable';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';

export const QRAnalyticsByDevice = () => {
    // Get Values from Query Params
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const fromDate =
        queryParams.get('from_date') ||
        format(startOfDay(subDays(new Date(), 30)), "yyyy-MM-dd'T'HH:mm:ss");
    const toDate =
        queryParams.get('to_date') || format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss");
    const linkId = queryParams.get('link_id');

    const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [type, setType] = useState('os_family');

    const fetchDevice = async () => {
        setLoading(true);
        await new AnalyticsQRService()
            .device(defaultWorkspace?._id || '', type, linkId, fromDate, toDate)
            .then((res) => {
                if (res.data.status && res.data.data.length > 0) {
                    setList(res.data.data);
                } else {
                    setList([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        setLoading(false);
    };

    const renderOSFamily = (item: any) => {
        return <Flex align={'center'}>{item.os_family}</Flex>;
    };

    const renderDeviceFamily = (item: any) => {
        return <Flex align={'center'}>{item.device_model || 'Unknown'}</Flex>;
    };

    useEffect(() => {
        fetchDevice();
    }, [type, linkId, fromDate, toDate]);

    return (
        <>
            <Box
                bg={'white'}
                h={450}
                style={{
                    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
                    border: '1px solid var(--mantine-color-gray-2)',
                    borderRadius: 4,
                }}
            >
                <Flex align={'center'} p={12}>
                    <Text
                        style={{
                            flexGrow: 1,
                        }}
                        fw={600}
                    >
                        By Operating System
                    </Text>
                    <Flex>
                        <SegmentedControl
                            size="xs"
                            value={type}
                            data={[
                                { label: 'Operation System', value: 'os_family' },
                                { label: 'Device', value: 'device_model' },
                            ]}
                            onChange={setType}
                        />
                    </Flex>
                </Flex>
                <Divider />

                {loading ? (
                    <>
                        <Flex mt={64} align={'center'} justify={'center'}>
                            <Loader size="sm" />
                        </Flex>
                    </>
                ) : (
                    <>
                        {list.length === 0 ? (
                            <Box pt={96}>
                                <NoResultsListTable message={'No device data available'} />
                            </Box>
                        ) : (
                            <ScrollArea h={380} scrollbarSize={6}>
                                <Table
                                    stickyHeader
                                    striped
                                    verticalSpacing={'xs'}
                                    style={{
                                        borderLeft: '1px solid var(--mantine-color-gray-2)',
                                    }}
                                >
                                    <Table.Thead h={4} fz="xs">
                                        <Table.Tr>
                                            <Table.Th w={'40%'}>
                                                <></>
                                            </Table.Th>
                                            <Table.Th ta="center">Total Clicks</Table.Th>
                                            <Table.Th ta="center">Unique Clicks</Table.Th>
                                            <Table.Th ta="center">QR Scans</Table.Th>
                                        </Table.Tr>
                                    </Table.Thead>
                                    <Table.Tbody>
                                        {list.map((item: any) => (
                                            <Table.Tr key={item[type]}>
                                                <Table.Td w={'40%'}>
                                                    {type === 'os_family' ? (
                                                        <>{renderOSFamily(item)}</>
                                                    ) : (
                                                        <>{renderDeviceFamily(item)}</>
                                                    )}
                                                </Table.Td>
                                                <Table.Td ta="center">{numberToCommas(item.clicks)}</Table.Td>
                                                <Table.Td ta="center">{numberToCommas(item.unique_clicks)}</Table.Td>
                                                <Table.Td ta="center">{numberToCommas(item.qr_scans)}</Table.Td>
                                            </Table.Tr>
                                        ))}
                                    </Table.Tbody>
                                </Table>
                            </ScrollArea>
                        )}
                    </>
                )}
            </Box>
        </>
    );
};