import { DateMenu } from '@/components/Menus/DateMenu/DateMenu';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { Box, Divider, Flex, Grid, Loader, Select, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import { LinksService } from '@/services/LinksService';
import CustomDateMenu from '@/components/Menus/DateMenu/CustomDateMenu';
import InfiniteScrollSelect from '@/components/InfiniteScrollSelect/InfiniteScrollSelect';
import { QRAnalyticsOverview } from './Components/Qrlinks/QRAnalyticsOverview';
import { QRAnalyticsHistogram } from './Components/Qrlinks/QRAnalyticsHistogram';
import { QRAnalyticsByReferral } from './Components/Qrlinks/QRAnalyticsByReferral';
import { QRAnalyticsByLocation } from './Components/Qrlinks/QRAnalyticsByLocation';
import { QRAnalyticsByBrowser } from './Components/Qrlinks/QRAnalyticsByBrowser';
import { QRAnalyticsByDevice } from './Components/Qrlinks/QRAnalyticsByDevice';

export const AnalyticsQRLinksPage = () => {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [selectedLink, setSelectedLink] = useState<string | null>(queryParams.get('link_id'));
  const [linksSuggestions, setLinksSuggestions] = useState<any[]>([]);
  const [linkPage, setLinkPage] = useState(1);
  const [hasMoreLinks, setHasMoreLinks] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoadingLinks, setIsLoadingLinks] = useState(false);
  const navigate = useNavigate();

  /**
   * Handle date change
   * @param value
   */
  const handleDateChange = (startDate: string | null, endDate: string | null, granularity: string) => {
    if (!startDate || !endDate) {
      // Handle "All time" case
      queryParams.set(
        'from_date',
        startOfDay(new Date(defaultWorkspace?.created_at || '')).toISOString()
      );
      queryParams.set('to_date', endOfDay(new Date()).toISOString());
    } else {
      queryParams.set('from_date', startDate);
      queryParams.set('to_date', endDate);
    }
    const newSearch = `?${queryParams.toString()}`;
    navigate(newSearch);
  };

  const fetchQRLinks = async (page: number, append: boolean = false, search: string = '') => {
    if (!defaultWorkspace?._id || isLoadingLinks) return;

    setIsLoadingLinks(true);
    try {
      // Fetch links that have QR scans
      const res = await new LinksService().getQRLinks(
        defaultWorkspace._id,
        page,
        20,
        search
      );

      if (res.data?.status) {
        const newLinks = res.data?.data || [];
        if (!Array.isArray(newLinks)) {
          console.error('Expected array of links but got:', newLinks);
          return;
        }
        
        setLinksSuggestions(prev => append ? [...prev, ...newLinks] : newLinks);
        setHasMoreLinks(res.data?.meta?.hasMore || false);
        setLinkPage(page);
      } else {
        console.error('API returned unsuccessful status');
      }
    } catch (error) {
      console.error('Error fetching QR links:', error);
    } finally {
      setIsLoadingLinks(false);
    }
  };

  const handleLinkSearch = (query: string) => {
    setSearchQuery(query);
  };

  const loadMoreLinks = () => {
    if (hasMoreLinks && !isLoadingLinks) {
      fetchQRLinks(linkPage + 1, true, searchQuery);
    }
  };

  const handleLinkChange = (value: string | null) => {
    setSelectedLink(value);
    queryParams.set('link_id', value || '');
    const newSearch = `?${queryParams.toString()}`;
    navigate(newSearch);
  };

  // Single useEffect to handle initial load and workspace changes
  useEffect(() => {
    if (!defaultWorkspace?._id) return;

    const linkId = queryParams.get('link_id');
    if (linkId !== selectedLink) {
      setSelectedLink(linkId);
    }

    setLinkPage(1);
    setHasMoreLinks(true);
    setSearchQuery('');
    fetchQRLinks(1, false);
  }, [defaultWorkspace?._id]); // Only depend on workspace changes

  // Handle search with debounce
  useEffect(() => {
    if (!defaultWorkspace?._id) return;

    const timeoutId = setTimeout(() => {
      setLinkPage(1);
      fetchQRLinks(1, false, searchQuery);
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  return (
    <>
      <Divider />
      <Flex
        mt={16}
        align={'center'}
      >
        <Flex
          style={{
            flexGrow: 1,
          }}
        >
          <InfiniteScrollSelect
            options={linksSuggestions}
            value={selectedLink}
            onChange={handleLinkChange}
            onSearch={handleLinkSearch}
            onScrollEnd={loadMoreLinks}
            placeholder="Filter by a QR link"
            isLoading={isLoadingLinks}
            canClear={true}
          />
        </Flex>
        <CustomDateMenu
          showButtons={{
            all_time: true,
            last_24_hours: true,
            last_7_days: true,
            last_14_days: true,
            last_30_days: true,
            last_90_days: false,
            last_6_months: true,
            last_1_year: true,
            this_month: true,
            last_month: true,
            "7D": false,
            "14D": false,
            "30D": false,
            "3M": false,
            "6M": false,
            "12M": false,
            "no-range": false,
            custom: true
          }}
          onDateChange={handleDateChange}
          value={[
            queryParams.get('from_date') || null,
            queryParams.get('to_date') || null
          ]}
          withGranularity={false}
        />
      </Flex>
      <Box my={16}>
        <Grid>
          <Grid.Col span={12}>
            <QRAnalyticsOverview />
          </Grid.Col>
          <Grid.Col span={12}>
            <Box
              h={306}
              bg={'white'}
              style={{
                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
                border: '1px solid var(--mantine-color-gray-2)',
                borderRadius: 4,
                padding: 16,
              }}
            >
              <Flex direction={'column'}>
                <Text fw={600} c="dimmed" mb={12}>
                  QR Scans vs Total Clicks
                </Text>
                <QRAnalyticsHistogram />
              </Flex>
            </Box>
          </Grid.Col>
        </Grid>
      </Box>
      <Grid>
        <Grid.Col span={6}>
          <QRAnalyticsByReferral />
        </Grid.Col>
        <Grid.Col span={6}>
          <QRAnalyticsByLocation />
        </Grid.Col>
        <Grid.Col span={6}>
          <QRAnalyticsByBrowser />
        </Grid.Col>
        <Grid.Col span={6}>
          <QRAnalyticsByDevice />
        </Grid.Col>
      </Grid>
    </>
  );
};