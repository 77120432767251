// Create a new file AnalyticsQRService.ts
import axios from './JWTService';

export class AnalyticsQRService {
  overview = (
    workspace_id: string,
    link_id: string | null,
    from_date: string,
    to_date: string
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post('/metrics_qr/overview', {
          workspace_id,
          from_date,
          to_date,
          link_id,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  histogram = (
    workspace_id: string,
    link_id: string | null,
    from_date: string,
    to_date: string
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post('/metrics_qr/histogram', {
          workspace_id,
          link_id,
          from_date,
          to_date,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  referrer = (
    workspace_id: string,
    link_id: string | null,
    from_date: string,
    to_date: string
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post('/metrics_qr/referrer', {
          workspace_id,
          link_id,
          from_date,
          to_date,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  location = (
    workspace_id: string,
    type: string,
    link_id: string | null,
    from_date: string,
    to_date: string
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/metrics_qr/location/${type}`, {
          workspace_id,
          link_id,
          from_date,
          to_date,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  browser = (
    workspace_id: string,
    link_id: string | null,
    from_date: string,
    to_date: string
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/metrics_qr/browser/list`, {
          workspace_id,
          link_id,
          from_date,
          to_date,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  device = (
    workspace_id: string,
    type: string,
    link_id: string | null,
    from_date: string,
    to_date: string
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/metrics_qr/device/${type}`, {
          workspace_id,
          link_id,
          from_date,
          to_date,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}