import { useLinkSetupStore } from '@/stores/useLinkSetupStore';
import { Box, Flex, Text } from '@mantine/core';
import classes from '../LinkCreationModal.module.css';

export const LinkCloaking = () => {
  const [link, setLink] = useLinkSetupStore((state) => [state.link, state.setLink]);

  return (
    <Box
      p={16}
      style={{
        overflowY: 'auto',
      }}
      className={!link.is_cloaked ? classes.disabled : ''}
    >
      <Flex align={'center'}>
        <Text
          fw={600}
          fz={14}
          style={{
            flexGrow: 1,
          }}
        >
          Link Cloaking
        </Text>
      </Flex>
      
      <Text size="sm" mt={16} color="dimmed">
        Link cloaking prevents users from seeing your destination URL. The content from the destination
        will be displayed within your shortened URL's domain, keeping your branding consistent.
      </Text>
      
      <Text size="sm" mt={16} color="dimmed">
        This feature is available only for shortener campaigns and works best with HTML content.
      </Text>
    </Box>
  );
};