import { Box, Text, Flex, ActionIcon, Image, Button, Tooltip, Group, Paper, Badge } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faDownload, faCopy, faQrcode, faLink, faFileSvg, faFilePng, faImage, faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { getQRAsSVGDataUri, getQRAsCanvas } from '@/utils/qr';
import { getRelativeTime } from '@/utils/DateUtility';
import { useClipboard } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useTagsStore } from '@/stores/useTagsStore';
import { appendQrParameter } from '@/utils/LinkUtility';

export function QRCodeCard({ qrCode, onEdit, onDelete }: { qrCode: any, onEdit: () => void, onDelete: () => void }) {
    const clipboard = useClipboard();
    const [tags] = useTagsStore((state) => [state.tags]);

    const truncateUrl = (url: string, maxLength: number = 40) => {
        const cleanUrl = url.replace(/^https?:\/\//, '');
        if (cleanUrl.length <= maxLength) return cleanUrl;
        
        const start = cleanUrl.substring(0, maxLength / 2);
        const end = cleanUrl.substring(cleanUrl.length - maxLength / 2);
        return `${start}...`;
    };

    // Function to find the tag name from the tags store
    const findTagName = (tagId: string) => {
        const tag = tags.find((t: any) => t._id === tagId);
        // @ts-ignore
        return tag?.name || tagId;
    };

    // Function to download QR code as PNG
    const downloadQrAsPng = async () => {
        const baseUrl = qrCode.source_type === 'bio' ? 
            `${qrCode.bio_link?.domain_attributes?.url || 'http://localhost:3000/'}${qrCode.bio_link?.short_url}` :
            `${qrCode.link?.domain_attributes?.url || 'http://localhost:3000/'}${qrCode.link?.short_url}`;

        const url = appendQrParameter(baseUrl);

        const dataUrl = await getQRAsCanvas({
            value: url,
            fgColor: qrCode.foreground_color,
            bgColor: qrCode.background_color
        }, 'image/png');

        const link = document.createElement('a');
        link.download = `${qrCode.title || 'qr-code'}.png`;
        link.href = dataUrl as string;
        link.click();
    };

    return (
        <Paper 
            w={500} 
            shadow="sm" 
            radius="lg" 
            withBorder 
            style={{ 
                overflow: 'hidden',
                transition: 'transform 0.2s, box-shadow 0.2s',
                ':hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)'
                }
            }}
        >
            <Flex style={{ minHeight: 200 }}>
                {/* Left side - QR Code */}
                <Box style={{ 
                    width: 200,
                    minWidth: 200,
                    background: qrCode.background_color || '#ffffff',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '20px',
                    position: 'relative'
                }}>
                    <Box 
                        style={{ 
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: `linear-gradient(45deg, ${qrCode.background_color || '#ffffff'} 0%, #f8f9fa 100%)`,
                            opacity: 0.9
                        }} 
                    />
                    <Box style={{ 
                        position: 'relative', 
                        width: '100%',
                        paddingTop: '100%', // This creates a square aspect ratio
                    }}>
                        <Box style={{
                            position: 'absolute',
                            top: '10px',
                            left: '10px',
                            right: '10px',
                            bottom: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Image
                                src={getQRAsSVGDataUri({
                                    value: appendQrParameter(qrCode.source_type === 'bio' ? 
                                        `${qrCode.bio_link?.domain_attributes?.url || 'http://localhost:3000/'}${qrCode.bio_link?.short_url}` :
                                        `${qrCode.link?.domain_attributes?.url || 'http://localhost:3000/'}${qrCode.link?.short_url}` || ""),
                                    fgColor: qrCode.foreground_color,
                                    bgColor: qrCode.background_color
                                })}
                                alt={qrCode.title}
                                style={{ 
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain'
                                }}
                            />
                        </Box>
                    </Box>
                    
                    {/* Action Buttons */}
                    <Group gap={8} mt="md" style={{ position: 'relative' }}>
                        <Tooltip label="Edit">
                            <ActionIcon 
                                variant="filled" 
                                color="blue" 
                                onClick={onEdit}
                                style={{ backgroundColor: 'rgba(231, 245, 255, 0.9)', color: '#1c7ed6' }}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </ActionIcon>
                        </Tooltip>
                        <Tooltip label="Delete">
                            <ActionIcon 
                                variant="filled" 
                                color="red" 
                                onClick={onDelete}
                                style={{ backgroundColor: 'rgba(255, 245, 245, 0.9)', color: '#fa5252' }}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </ActionIcon>
                        </Tooltip>
                        <Tooltip label="Download SVG">
                            <ActionIcon
                                variant="filled"
                                color="indigo"
                                style={{ backgroundColor: 'rgba(237, 242, 255, 0.9)', color: '#4c6ef5' }}
                                onClick={() => {
                                    const link = document.createElement('a');
                                    link.download = `${qrCode.title || 'qr-code'}.svg`;
                                    link.href = getQRAsSVGDataUri({
                                        value: appendQrParameter(qrCode.source_type === 'bio' ? 
                                            `${qrCode.bio_link?.domain_attributes?.url || 'http://localhost:3000/'}${qrCode.bio_link?.short_url}` :
                                            `${qrCode.link?.domain_attributes?.url || 'http://localhost:3000/'}${qrCode.link?.short_url}` || ""),
                                        fgColor: qrCode.foreground_color,
                                        bgColor: qrCode.background_color
                                    });
                                    link.click();
                                }}
                            >
                                <FontAwesomeIcon icon={faFileSvg} />
                            </ActionIcon>
                        </Tooltip>
                        <Tooltip label="Download PNG">
                            <ActionIcon
                                variant="filled"
                                color="violet"
                                style={{ backgroundColor: 'rgba(243, 240, 255, 0.9)', color: '#7950f2' }}
                                onClick={downloadQrAsPng}
                            >
                                <FontAwesomeIcon icon={faFilePng} />
                            </ActionIcon>
                        </Tooltip>
                    </Group>
                </Box>

                {/* Right side - Details */}
                <Box style={{ flex: 1, background: '#ffffff' }} p="xl">
                    <Text size="lg" fw={600} c="#003aa9" truncate mb="xs" style={{ lineHeight: 1.3 }}>
                        {qrCode.title || 'Untitled'}
                    </Text>

                    <Text size="sm" c="dimmed" lineClamp={1} mb="md" style={{ lineHeight: 1.5 }}>
                        {qrCode.description || 'No description'}
                    </Text>

                    <Flex align="center" gap={6}>
                        <FontAwesomeIcon 
                            icon={qrCode.source_type === 'bio' ? faImage : qrCode.source_type === 'link' ? faArrowUpRightFromSquare : faQrcode}
                            style={{ 
                                width: 17, 
                                height: 17,
                                color: qrCode.source_type === 'bio'
                                    ? '#3366ff'
                                    : qrCode.source_type === 'link'
                                        ? '#0ea5e9'
                                        : '#16a34a'
                            }}
                        />
                        <Text 
                            size="xs" 
                            style={{ 
                                color: qrCode.source_type === 'bio'
                                    ? '#3366ff'
                                    : qrCode.source_type === 'link'
                                        ? '#0ea5e9'
                                        : '#16a34a',
                                fontWeight: 500
                            }}
                        >
                            {qrCode.source_type === 'bio' 
                                ? 'Bio Link' 
                                : qrCode.source_type === 'link' 
                                    ? 'Link'
                                    : 'New Link'}
                        </Text>
                    </Flex>

                    {/* Show link information based on source type */}
                    {(qrCode.link || qrCode.bio_link) && (
                        <Box mb="md">
                            {/* Destination URL - show message for bio links without URL */}
                            {(qrCode.source_type === 'bio') ? (
                                <Flex align="center" mb={2} mt={5}>
                                    <Box mr="xs" style={{ color: '#003aa9', width: 16 }}>
                                        <FontAwesomeIcon icon={faLink} size="sm" />
                                    </Box>
                                    <Text size="xs" fw={500} style={{ flex: 1 }} c={qrCode.bio_link?.url ? 'inherit' : 'dimmed'}>
                                        {qrCode.bio_link?.url ? 
                                            truncateUrl(qrCode.bio_link.url) :
                                            'No destination URL'
                                        }
                                    </Text>
                                </Flex>
                            ) : (
                                qrCode.link?.url && (
                                    <Flex align="center" mb={2} mt={5}>
                                        <Box mr="xs" style={{ color: '#003aa9', width: 16 }}>
                                            <FontAwesomeIcon icon={faLink} size="sm" />
                                        </Box>
                                        <Text size="xs" fw={500} style={{ flex: 1 }} truncate>
                                            {truncateUrl(qrCode.link.url)}
                                        </Text>
                                    </Flex>
                                )
                            )}

                            {/* Shortened URL */}
                            <Flex align="center">
                                <Box mr="xs" style={{ color: '#003aa9', width: 16 }}>
                                    <FontAwesomeIcon icon={faQrcode} size="sm" />
                                </Box>
                                <Text size="xs" fw={500} style={{ flex: 1 }} truncate>
                                    {qrCode.source_type === 'bio' ? 
                                        truncateUrl(`${qrCode.bio_link?.domain_attributes?.url || 'http://localhost:3000/'}${qrCode.bio_link?.short_url}`, 35) :
                                        truncateUrl(`${qrCode.link?.domain_attributes?.url || 'http://localhost:3000/'}${qrCode.link?.short_url}`, 35)
                                    }
                                </Text>
                                <Tooltip label="Copy link">
                                    <ActionIcon
                                        variant="subtle"
                                        color="blue"
                                        size="sm"
                                        onClick={() => {
                                            const link = qrCode.source_type === 'bio' ?
                                                `${qrCode.bio_link?.domain_attributes?.url || 'http://localhost:3000/'}${qrCode.bio_link?.short_url}` :
                                                `${qrCode.link?.domain_attributes?.url || 'http://localhost:3000/'}${qrCode.link?.short_url}`;
                                            clipboard.copy(link);
                                            notifications.show({
                                                color: 'green',
                                                message: 'Link copied to clipboard',
                                            });
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faCopy} />
                                    </ActionIcon>
                                </Tooltip>
                            </Flex>
                        </Box>
                    )}

                    {/* Only show campaign for regular links */}
                    {qrCode.source_type !== 'bio' && qrCode.campaign && (
                        <Text size="xs" c="dimmed" fw={500} mb="xs">
                            Campaign: <Text span c="#003aa9" fw={600}>{qrCode.campaign.name}</Text>
                        </Text>
                    )}

                    {/* Tags Section - always show the container for consistent spacing */}
                    <Box mb="xs" style={{ maxWidth: '100%', overflow: 'hidden', minHeight: 28 }}>
                        {qrCode.source_type === 'bio' ? (
                            qrCode.bio_link?.tags && qrCode.bio_link.tags.length > 0 ? (
                                <Group gap={8} wrap="nowrap" style={{ overflow: 'hidden' }}>
                                    {qrCode.bio_link.tags.slice(0, 3).map((tag: any, index: number) => {
                                        const tagName = typeof tag === 'string' ? findTagName(tag) : tag.name;
                                        return (
                                            <Paper
                                                key={index}
                                                p={6}
                                                style={{
                                                    backgroundColor: '#edf2ff',
                                                    borderRadius: '4px',
                                                    flexShrink: 0
                                                }}
                                            >
                                                <Text size="xs" c="#003aa9" fw={500}>
                                                    {tagName}
                                                </Text>
                                            </Paper>
                                        );
                                    })}
                                    {qrCode.bio_link.tags.length > 3 && (
                                        <Text size="xs" c="dimmed" fw={500} style={{ flexShrink: 0 }}>
                                            +{qrCode.bio_link.tags.length - 3} more
                                        </Text>
                                    )}
                                </Group>
                            ) : (
                                <Text size="xs" c="dimmed">No tags</Text>
                            )
                        ) : (
                            qrCode.link?.tags && qrCode.link.tags.length > 0 ? (
                                <Group gap={8} wrap="nowrap" style={{ overflow: 'hidden' }}>
                                    {qrCode.link.tags.slice(0, 3).map((tag: any, index: number) => {
                                        const tagName = typeof tag === 'string' ? findTagName(tag) : tag.name;
                                        return (
                                            <Paper
                                                key={index}
                                                p={6}
                                                style={{
                                                    backgroundColor: '#edf2ff',
                                                    borderRadius: '4px',
                                                    flexShrink: 0
                                                }}
                                            >
                                                <Text size="xs" c="#003aa9" fw={500}>
                                                    {tagName}
                                                </Text>
                                            </Paper>
                                        );
                                    })}
                                    {qrCode.link.tags.length > 3 && (
                                        <Text size="xs" c="dimmed" fw={500} style={{ flexShrink: 0 }}>
                                            +{qrCode.link.tags.length - 3} more
                                        </Text>
                                    )}
                                </Group>
                            ) : (
                                <Text size="xs" c="dimmed">No tags</Text>
                            )
                        )}
                    </Box>

                    <Text size="xs" c="dimmed" fw={500} mb="lg">
                        Created {getRelativeTime(qrCode.created_at)}
                    </Text>
                </Box>
            </Flex>
        </Paper>
    );
}