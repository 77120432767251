import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { Flex, SegmentedControl, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

export function AnalyticsPage() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [value, setValue] = useState(() => {
    if (window.location.href.includes('/bio-links')) return 'bio-links';
    if (window.location.href.includes('/qr-links')) return 'qr-links';
    return 'short-links';
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (value === 'short-links') {
      // check if the url already has the value of the selected tab
      if (window.location.href.includes('short-links')) {
        return;
      }
      navigate(`/analytics/${defaultWorkspace?._id}/short-links`);
    } else if (value === 'bio-links') {
      if (window.location.href.includes('bio-links')) {
        return;
      }
      navigate(`/analytics/${defaultWorkspace?._id}/bio-links`);
    } else if (value === 'qr-links') {
      if (window.location.href.includes('qr-links')) {
        return;
      }
      navigate(`/analytics/${defaultWorkspace?._id}/qr-links`);
    }
  }, [value, defaultWorkspace, navigate]);

  return (
    <>
      <Flex direction={'column'} mx="lg" mt="sm">
        <Flex mb={'md'}>
          <Flex
            style={{
              flexGrow: 1,
            }}
          >
            <Text size="xl" fw="bold" mr={'xs'}>
              Analytics {value === 'short-links' ? 'Short Links' : value === 'bio-links' ? 'Bio Links' : 'QR Links'}
            </Text>
          </Flex>

          <Flex justify={'end'}>
            <SegmentedControl
              size="xs"
              w={330} // Increased width to accommodate new tab
              color="primary"
              data={[
                { label: 'Short Links', value: 'short-links' },
                { label: 'Bio Links', value: 'bio-links' },
                { label: 'QR Links', value: 'qr-links' },
              ]}
              value={value}
              onChange={setValue}
            />
          </Flex>
        </Flex>{' '}
        <Outlet />
      </Flex>
    </>
  );
}